import revive_payload_client_kzOvf8abCv from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rkAsD5SVDw from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UDgOUy48uS from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_LqeCqtajMk from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.6.3_rollup@4.14.1_vite@5.2.8_@types+node@20.12.7_sug_dzjhps4faplsksqrbmndvi2com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_sCf2Qt3DCN from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_VdgSLYrWNG from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_fQbOftaX54 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_WjPjQqnPBP from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_BAn4L52UGs from "/vercel/path0/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.14.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_x9S61xn37W from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encodi_7w3m7kfxd25whhbpujce6u65ni/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_Nfn1ZPCwZE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.1_vue@3.4.21_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_o9PtdxnrKC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.14.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_dMQ0C1bZzc from "/vercel/path0/node_modules/.pnpm/nuxt-zod-i18n@1.6.0_rollup@4.14.1/node_modules/nuxt-zod-i18n/dist/runtime/plugin.mjs";
import chunk_reload_client_fhwX9CegWD from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_eslint@8.57.1_ioredis@5_ndwqvuablopxymxtszc4wjpf6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import logger_kEXgbufg9p from "/vercel/path0/apps/web/plugins/logger.ts";
import sanity_iFCuiLdTZU from "/vercel/path0/apps/web/plugins/sanity.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/web/plugins/sentry.client.ts";
import abTestConfig_client_eop05oHQVI from "/vercel/path0/apps/web/plugins/abTestConfig.client.ts";
import amplitude_qcoZpE5aTU from "/vercel/path0/apps/web/plugins/amplitude.ts";
import cookie_information_client_sZJQFPleMk from "/vercel/path0/apps/web/plugins/cookie-information.client.ts";
import dompurify_html_VcfsMfUvBB from "/vercel/path0/apps/web/plugins/dompurify-html.ts";
import dotlottie_player_client_Oxn7guf2ah from "/vercel/path0/apps/web/plugins/dotlottie-player.client.ts";
import feedbucket_client_bcif5LEtbp from "/vercel/path0/apps/web/plugins/feedbucket.client.ts";
import performance_logger_client_X8KKaDc9Dp from "/vercel/path0/apps/web/plugins/performance-logger.client.ts";
import setUtmSourceFallback_client_DWMGODzPFN from "/vercel/path0/apps/web/plugins/setUtmSourceFallback.client.ts";
export default [
  revive_payload_client_kzOvf8abCv,
  unhead_rkAsD5SVDw,
  router_UDgOUy48uS,
  _0_siteConfig_LqeCqtajMk,
  payload_client_sCf2Qt3DCN,
  check_outdated_build_client_VdgSLYrWNG,
  plugin_vue3_fQbOftaX54,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WjPjQqnPBP,
  nuxt_plugin_BAn4L52UGs,
  plugin_x9S61xn37W,
  i18n_Nfn1ZPCwZE,
  plugin_o9PtdxnrKC,
  plugin_dMQ0C1bZzc,
  chunk_reload_client_fhwX9CegWD,
  logger_kEXgbufg9p,
  sanity_iFCuiLdTZU,
  sentry_client_shVUlIjFLk,
  abTestConfig_client_eop05oHQVI,
  amplitude_qcoZpE5aTU,
  cookie_information_client_sZJQFPleMk,
  dompurify_html_VcfsMfUvBB,
  dotlottie_player_client_Oxn7guf2ah,
  feedbucket_client_bcif5LEtbp,
  performance_logger_client_X8KKaDc9Dp,
  setUtmSourceFallback_client_DWMGODzPFN
]